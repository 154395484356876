import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import ElapsedTimeSection from './ElapsedTimeSection';

const gradeMapping = {
  1: <span className="button is-danger">Red</span>,
  2: <span className="button is-warning">Yellow</span>,
  3: <span className="button is-success">Green</span>
};

class StopTrainingGradePage extends Component {
  state = {
    modalOpen: false,
    loading: false,
    elapsedTime: ''
  };

  get currentTrainingSession() {
    const { trainingSessions } = this.props.candidate;
    const unfinishedSessions = trainingSessions.filter(session => {
      return session.type === '5_MIN' && !session.end_time;
    });

    if (unfinishedSessions.length > 0) {
      return unfinishedSessions[0];
    }

    return null;
  }

  updateElapsedTime() {
    if (!this.currentTrainingSession) {
      return;
    }

    const startTimeM = moment.tz(this.currentTrainingSession.start_time, 'America/Los_Angeles');
    const timeNowM = moment().tz('America/Los_Angeles');
    const elapsedTime = timeNowM.diff(startTimeM, 'seconds');

    this.setState({ elapsedTime });
  }

  logOngoingTraining() {
    if (!this.currentTrainingSession) {
      return;
    }

    this.props.postUserLog({
      type: 'TRAINING_CANDIDATE',
      details: {
        candidateId: this.props.candidate.id,
        testSessionId: this.props.testSessionId,
        trainingType: '5_MIN'
      }
    });
  }

  handleStartTrainingClick = async () => {
    try {
      await this.props.confirmStartTraining(this.props.candidate.id, this.props.testSessionId, '5_MIN');
    } catch (e) {
      console.error(e);
    }
  };

  handleGradeClick = e => {
    const grade = parseInt(e.currentTarget.dataset.grade, 10);

    this.props.setGrade(grade, () => {
      this.setState({ modalOpen: true });
    });
  };

  handleGradeConfirm = () => {
    this.setState({ loading: true }, async () => {
      try {
        if (this.currentTrainingSession) {
          await this.props.confirmStopTraining(
            this.props.candidate.id,
            this.props.testSessionId,
            this.props.type,
            this.props.grade,
            null,
            this.currentTrainingSession.id
          );
        } else {
          await this.props.confirmStopTraining(
            this.props.candidate.id,
            this.props.testSessionId,
            this.props.type,
            this.props.grade,
            null
          );
        }
        this.setState({ loading: false });
        this.props.history.push(`/class/${this.props.testSessionId}`);
      } catch (e) {
        console.error(e);
      }
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount() {
    this.intervalTimer = setInterval(() => {
      if (this.currentTrainingSession) {
        this.updateElapsedTime();
      }
    }, 1000);

    this.intervalLogger = setInterval(() => {
      if (this.state.elapsedTime >= 300) {
        this.notificationSound.play();
      }

      this.logOngoingTraining();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
    clearInterval(this.intervalLogger);
  }

  render() {
    const { props, currentTrainingSession } = this;

    if (currentTrainingSession) {
      return (
        <Fragment>
          <ElapsedTimeSection
            elapsedTime={this.state.elapsedTime}
            label="5-minute Pre-assessment"
            timeLimitSeconds={300}
          />
          <audio
            ref={notificationSound => {
              this.notificationSound = notificationSound;
            }}
          >
            <source src="./time-up.mp3" type="audio/mp3" />
          </audio>
          <section>
            <div>To complete 5-minute pre-assessment, please select a group for {props.candidate.name}:</div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <button
                type="buton"
                onClick={this.handleGradeClick}
                data-grade={1}
                className="button is-large is-danger"
                style={{ flexBasis: '100%', margin: '18px' }}
              >
                Red
              </button>
              <button
                type="button"
                onClick={this.handleGradeClick}
                data-grade={2}
                className="button is-large is-warning"
                style={{ flexBasis: '100%', margin: '18px' }}
              >
                Yellow
              </button>
              <button
                type="button"
                onClick={this.handleGradeClick}
                data-grade={3}
                className="button is-large is-success"
                style={{ flexBasis: '100%', margin: '18px' }}
              >
                Green
              </button>
            </div>
          </section>
          <div className={`modal ${this.state.modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Confirm Group</p>
              </header>
              <section className="modal-card-body" style={{ display: 'flex', alignItems: 'center' }}>
                <span>{props.candidate.name} will be added to group&nbsp;</span>
                {gradeMapping[this.props.grade]}
              </section>
              <footer className="modal-card-foot" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="button" onClick={this.closeModal}>
                  Cancel
                </button>
                <button
                  className={`button is-success ${this.state.loading ? 'is-loading' : ''}`}
                  onClick={this.handleGradeConfirm}
                >
                  Confirm
                </button>
              </footer>
            </div>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button type="button" onClick={this.handleStartTrainingClick} className="button is-large is-info">
            Start 5-Min. Pre-assessment
          </button>
        </section>
      </Fragment>
    );
  }
}

export default StopTrainingGradePage;
