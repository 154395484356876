import React, { Component } from 'react';
import { summarizeTrainingSessions } from '../../../lib/trainingSessions';

class StopTrainingTypePage extends Component {
  handlePreAssessmentClick = () => {
    this.props.setType('5_MIN', () => {
      this.props.history.push(`${this.props.match.url}/grade`);
    });
  };

  handleFamiliarizationClick = () => {
    this.props.setType('20_MIN', () => {
      this.props.history.push(`${this.props.match.url}/sign`);
    });
  };

  render() {
    const { assessmentComplete, familiarizationComplete } = summarizeTrainingSessions(
      this.props.candidate.trainingSessions
    );

    return (
      <section>
        <div>Please select type of assessment/training to complete:</div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button
            type="button"
            onClick={this.handlePreAssessmentClick}
            className="button is-large is-info"
            disabled={assessmentComplete}
            style={{ display: 'block', minWidth: '100%', margin: '18px' }}
          >
            Pre-Assessment
          </button>
          <button
            type="button"
            onClick={this.handleFamiliarizationClick}
            className="button is-large is-warning"
            disabled={familiarizationComplete}
            style={{ display: 'block', minWidth: '100%', margin: '18px' }}
          >
            Familiarization Training
          </button>
        </div>
      </section>
    );
  }
}

export default StopTrainingTypePage;
