import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { confirmStartTraining, confirmStopTraining, postUserLog } from '../../../actionCreators';
import StopTrainingPage from './StopTrainingPage';
import StopTrainingTypePage from './StopTrainingTypePage';
import StopTrainingGradePage from './StopTrainingGradePage';

class StopTrainingRouter extends Component {
  state = {
    type: null,
    grade: null
  };

  setType = (type, callback) => {
    this.setState({ type }, callback);
  };

  setGrade = (grade, callback) => {
    this.setState({ grade }, callback);
  };

  render() {
    const { props } = this;
    return (
      <Switch>
        <Route
          exact
          path={`${props.match.url}/`}
          render={routeProps => (
            <StopTrainingPage
              type="20_MIN"
              grade={this.state.grade}
              testSessionId={props.testSessionId}
              candidate={props.candidate}
              trainingSession={props.trainingSession}
              confirmStartTraining={props.confirmStartTraining}
              confirmStopTraining={props.confirmStopTraining}
              postUserLog={postUserLog}
              {...routeProps}
            />
          )}
        />
        <Route
          path={`${props.match.url}/grade`}
          render={routeProps => (
            <StopTrainingGradePage
              rootPath={props.match.url}
              type={this.state.type}
              grade={this.state.grade}
              setGrade={this.setGrade}
              testSessionId={props.testSessionId}
              candidate={props.candidate}
              trainingSession={props.trainingSession}
              confirmStartTraining={props.confirmStartTraining}
              confirmStopTraining={props.confirmStopTraining}
              postUserLog={postUserLog}
              {...routeProps}
            />
          )}
        />
        <Route
          path={`${props.match.url}/sign`}
          render={routeProps => (
            <StopTrainingPage
              type="20_MIN"
              grade={this.state.grade}
              testSessionId={props.testSessionId}
              candidate={props.candidate}
              trainingSession={props.trainingSession}
              confirmStartTraining={props.confirmStartTraining}
              confirmStopTraining={props.confirmStopTraining}
              postUserLog={postUserLog}
              {...routeProps}
            />
          )}
        />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirmStartTraining,
      confirmStopTraining
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(StopTrainingRouter);
